import axios from "axios";
import { AUTH_TOKEN } from "redux/constants/Auth";
import FormData from "form-data";
console.log("process.env.NODE_ENV",  process.env.NODE_ENV)
const URL_BASE =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const TOKEN_PAYLOAD_KEY = "authorization";
const ENTRY_ROUTE = "/login";

class ApiService {
  _instance;

  constructor() {
    let path = "";
    if (typeof window !== "undefined") {
      path = window.location.pathname;
    }

    this._instance = axios.create({ baseURL: URL_BASE });
    this._instance.interceptors.request.use(
      function (config) {
        const jwtToken = localStorage.getItem(AUTH_TOKEN);

        if (jwtToken) {
          config.headers = {
            ...config.headers,
            [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
            "Content-Type": "multipart/form-data",

          };
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this._instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401 && path !== ENTRY_ROUTE) {
          localStorage.clear();
          //window.location.pathname = ENTRY_ROUTE;
        }
        return Promise.reject(error);
      }
    );
  }

  async signin(params) {
    return await this._instance.post("/login", params);
  }



  async logout(params) {
    return await this._instance.post("/logout", params);
  }



  async signup(params) {
    return await this._instance.post("/register", params);
  }

  async getUser() {
    return await this._instance.post("/authUser");
  }
  /*=======================================================*/
  async postSpecialties(params) {
    return await this._instance.post("/specialties", params);
  }


  async getSpecialties() {
    return await this._instance.get("/specialties");
  }

  async updateSpecialties(values) {

    console.log("values ",values)
    return await this._instance.put(`/specialties/${values.id}`, values);
  }
  /*=======================================================*/

  async postSubspecialties(params) {
    return await this._instance.post("/subspecialties", params);
  }


  async getSubspecialties() {
    return await this._instance.get("/subspecialties");
  }

  async updateSubspecialties(values) {
    return await this._instance.put(`/subspecialties/${values.id}`, values);
  }


  async postUpdatsubspecialties(params) {
    return await this._instance.post("/update-subspecialties", params);
  }



  /*=======================================================*/

  async postServices(params) {
    return await this._instance.post("/services", params);
  }


  async getServices() {
    return await this._instance.get("/services");
  }

  async updateServices(values) {
    return await this._instance.put(`/services/${values.id}`, values);
  }


  /*==============================================================*/


  async postDoctors(params) {
    return await this._instance.post("/doctors", params);
  }

  async getDoctors(parameters) {
    return await this._instance.get(`/doctors?start=${parameters.start}&end=${parameters.end}&letter=${parameters.letter}&city=${parameters.city}&specialtyId=${parameters.specialtyId}&subspecialtyId=${parameters.subspecialtyId}&serviceId=${parameters.serviceId}`);
  }


  async get2Doctors(id) {
    return await this._instance.get(`/doctors/${id}`);
  }



  async updateDoctors(values) {
 
   
    return await this._instance.post(`/doctors-update/${values.id}`, values);
  }


  async deleteDoctors(id) {
    return await this._instance.delete(`/doctors/${id}`);

  }


  async restoreDoctors(id) {
    return await this._instance.post(`/doctor-restore/${id}`);

  }




  async getAppointments(params) {
    
    return await this._instance.get(`/appointments/doctors/10?from=${params.from}&to=${params.to}`);
  }


  async updateAppointments(values) {
    return await this._instance.post(`/appointments/${values.id}`, values);
  }


  async getPatients(id) {
    return await this._instance.get(`/doctors/${id}/patients`);
  }

  async getAllPatients() {
    return await this._instance.get(`/patients`);
  }

  
  async exportPatients() {
    return await this._instance.get('/export-patients', {
      responseType: 'blob', // Necesario para manejar la descarga del archivo correctamente
    });
  }


  async getTransactions(params) {
    
    return await this._instance.get(`/transactions?from=${params.from}&to=${params.to}`);
  }


  async getPatientsOne(id) {
    return await this._instance.get(`/patients/${id}`);
  }


  async updateSpecialtyStatus(formData) {
    return await this._instance.post(`/specialities/${formData.get('id')}/update-status`, formData);
  }

  async updateSubspecialtyStatus(formData) {
    return await this._instance.post(`/subspecialties/${formData.get('id')}/update-status`, formData);
  }


  async updateServiceStatus(formData) {
    return await this._instance.post(`/service/${formData.get('id')}/update-status`, formData);
  }

  async getTotalAppointments() {
    return await this._instance.get(`/get-total-appointments`);
  }

  async getAppointments() {
    return await this._instance.get(`/get-appointments`);
  }

  async getAppointmentsbyId(id) {
    return await this._instance.get(`/appointments/${id}`);
  }


  async postVerifiedDoctor(formData) {

    
    return await this._instance.post(`/verifiedDoctor/${formData.id}?status=${formData.status}`);
  }


  async postUpdateDocuments(formData) {
    return await this._instance.post(`/update-documents/${formData.id}`,formData);
  }


  async getServicesForDoctor(id) {
    return await this._instance.get(`/doctors/${id}/services`);
  }

  async postAddService(formData) {
    return await this._instance.post(`/add-service`,formData);
  }

  async sendEmailRecoverPassword(formData) {
    return await this._instance.post(`/sendEmailRecoverPassword`,formData);
  }

  async sendEmailUpdateEmailAndPassword(formData) {
    return await this._instance.post(`/users/${formData.id}/update-email-and-password`, formData);
  }

  async getAllCities() {
    return await this._instance.get(`/getCities`);
  }

  async getSubspecialtiesBySpeciality(id) {
    return await this._instance.get(`/subspecialties-by-speciality?specialtyId=${id}`);
  }

  async getServicesBySubspecialty(id) {
    return await this._instance.get(`/services-by-subspecialty?subspecialtyId=${id}`);
  }

  async getListDoctors(parameters) {
    return await this._instance.get(`/doctors`,parameters);
  }

  async exportDoctors(params) {
    return await this._instance.get('/export-doctors', {
      params,
      responseType: 'blob', // Esto es necesario para que axios maneje la descarga del archivo correctamente
    });
  }
  


  async getAmountTransaction(id) {
    return await this._instance.get(`/amountTransaction?appointmentId=${id}`);
  }

  async getAllAppointments(parameters) {

    
    return await this._instance.get(`/allAppointments?start_date=${parameters.start_date}&end_date=${parameters.end_date}&status=${parameters.status}&doctor_name=${parameters.doctor_name}&patient_name=${parameters.patient_name}&email=${parameters.email}&city=${parameters.city}`);
  }
  async exportAppointments(params) {
    return await this._instance.get('/exportAppointments', {
      params,
      responseType: 'blob', // Esto es necesario para que axios maneje la descarga del archivo correctamente
    });
  }
  
  async getAllCities2() {

    return await this._instance.get(`/getCities`);
  }


  async getpaginationServices(params) {
    
    return await this._instance.get(`/paginationServices?page=${params.page}&pageSize=${params.pageSize}&name=${params.name}&subspecialty_id=${params.subspecialtyId}`);
  } 

  async getDoctorsByServices(params) {
    return await this._instance.get(`doctors-by-services?city=${params.city}&servicesArray=${params.serviceId}`);
  }
  



  async getEnabledSpecialties() {
    return await this._instance.get("/enabled-specialties");
  }

  async getEnabledSubspecialties() {
    return await this._instance.get("/enabled-subspecialties");
  }

  async getEnabledServices() {
    return await this._instance.get("/enabled-services");
  }



}

export default new ApiService();
